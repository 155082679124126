.sidebar {
  position: fixed;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  bottom: 0;
  width: 280px;
}

  .social-links {
    border: none; /* Reset border */
    border-radius: 0%;
}

.social-links a {
    font-size: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    line-height: 1;
    padding: 8px 0;
    border-radius: 50%;
    text-decoration: none;
    background-color: #323232;
    text-align: center;
    transition: color 0.3s;
}

.social-links a:hover {
    color: #4ab3f4; /* Change color on hover */
}

.bx-icons {
    padding-left: 10px;
    padding-right: 10px;
}
