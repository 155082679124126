#full-page-block {
    background: $header-bg-col;
    background-image: url("../img/zenith_bg.png");
    display: flex;
    position:absolute;
    top:0;
    vertical-align: bottom;
    width: 100%;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding-top: 100px;
  }
  
.header-block {
    z-index: 1;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 auto;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.site-title {
      font-size: 50px;
      display: block;
      line-height: 1;
      color: #FFF;
    }

.site-description {
    font-size: 20px;
    display: block;
    line-height: 1;
    color: #FFF;
    margin-top: 10px;
  }

  .down {
    color: #FFF;
    position: absolute;
    bottom: 25px;
    width: 100%;
    margin: 0 auto;
    display: block;
    text-align: center;
    font-size: 30px;
    cursor: pointer;
    .icon {
        position: absolute;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 100px;
        height: 100px;
        fill: #fff;
        -webkit-animation: pulse 1.3s infinite;
        animation: pulse 1.3s infinite;
        &:hover {
          color: #4B5664;
        }
        &:active {
          color: #4B5664;
        }
    }
  }