$header-bg-col: #111111;
$font-family-base: 'Roboto', sans-serif;


@import "main";
@import "header-block";
@import "fontawesome";
@import "sidebar";
@import "bootstrap-icons.css";
@import "roboto-font";
@import "job-cards";
@import "portfolio.scss";
@import "footer";
@import "aperture-files";